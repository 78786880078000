<template>
  <div class="content">
    <div class="back" @click="$router.back()">
      <icon-font type="icon-fanhui" />
    </div>

    <div class="dish-detail">
      <a-spin tip="Loading..." :spinning="spinning">
        <div class="image">
          <img :src="$filters.image(dish.pic)" :alt="dish.name" />
        </div>
        <div class="name">{{ dish.name }}</div>
        <div class="info">
          <span>{{ dish.tasteType }}</span>
          <span>{{ dish.unit }}</span>
        </div>
        <div class="price-action">
          <div class="price"><span>¥</span>{{ dish.sellingPrice }}</div>
          <div class="action">
            <span @click="onMinus">-</span>
            <span class="count">{{ count }}</span>
            <span @click="onPlus">+</span>
          </div>
        </div>
      </a-spin>
    </div>

    <div class="bottom">
      <div class="bottom-infos">
        <div class="dish-price">
          <span>¥</span>{{ price }}
          <span>数量：{{ count }}</span>
        </div>

        <div class="btns">
          <a-button class="btn" :loading="loading" @click="onSubmit(0)">
            加菜</a-button
          >
          <a-button class="btn" :loading="loading" @click="onSubmit(1)">
            赠菜</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { DishClass } from '@/apis/dish'
import { OrderItemClass } from '@/apis/order'
import { message } from 'ant-design-vue'
import router from '@/router'
import storage from 'store'

const api = new DishClass()
const orderItems = new OrderItemClass()
export default defineComponent({
  components: {},
  setup () {
    const dish = ref({})
    const id = ref(0)
    const count = ref(1)
    const loading = ref(false)
    const spinning = ref(false)

    const onGetDishDetail = (id) => {
      spinning.value = true
      api.detail(id).then((resp) => {
        dish.value = resp
        spinning.value = false
      })
    }
    const onMinus = () => {
      if (count.value <= 1) {
        count.value = 1
      } else {
        count.value--
      }
    }
    const onPlus = () => {
      count.value++
    }
    const onSubmit = (state) => {
      const orderId = storage.get('DISH_ORDER_ID')
      loading.value = true
      orderItems
        .add({
          orderId,
          dishId: id.value,
          count: count.value,
          state
        })
        .then(() => {
          loading.value = false
          message.success(state === 0 ? '加菜成功' : '赠菜成功')
          router.back()
        })
    }

    return {
      dish,
      id,
      count,
      loading,
      spinning,
      onGetDishDetail,
      onMinus,
      onPlus,
      onSubmit
    }
  },

  computed: {
    price () {
      return this.dish.sellingPrice * this.count
    }
  },

  created () {
    const id = this.$route.params.id
    this.id = id
    this.onGetDishDetail(id)
  },
  methods: {}
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f3f0f0;
  .image {
    border-bottom: 1px solid #ccc;
    background: #fff;
    img {
      display: block;
      width: 100%;
    }
  }
  .dish-detail {
    background: #fff;
    padding-bottom: 0.3rem;
    border-radius: 0 0 0.2rem 0.2rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }
  .name {
    font-size: 0.4rem;
    font-weight: 600;
    padding: 0.2rem 0.2rem 0 0.2rem;
  }
  .info {
    padding: 0.1rem 0.2rem 0 0.2rem;
    span {
      background: rgb(238, 233, 233);
      font-size: 0.2rem;
      margin-right: 0.1rem;
      padding: 1px 2px;
      border-radius: 2px;
    }
  }
  .price-action {
    padding: 0.1rem 0.2rem 0 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.8rem;
    .price {
      font-size: 0.5rem;
      color: #f50;
      font-weight: 600;
      line-height: 0.8rem;
      span {
        font-size: 0.24rem;
        font-weight: 400;
      }
    }
    .action {
      display: flex;
      span {
        display: block;
        border: 1px solid #ccc;
        border-radius: 3px;
        min-width: 0.6rem;
        height: 0.6rem;
        text-align: center;
        margin-left: 5px;
        &.count {
          color: #f50;
          font-weight: 600;
        }
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.2rem;
    .bottom-infos {
      background: #f50;
      height: 1rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.1rem 0.2rem;
      .dish-price {
        color: #fff;
        font-size: 0.6rem;
        font-weight: 600;
        span {
          font-size: 0.24rem;
          font-weight: 400;
        }
      }
      .btns {
        display: flex;
        .btn {
          color: #333;
          width: 1.5rem;
          height: 0.7rem;
          text-align: center;
          line-height: 0.7rem;
          margin-left: 0.15rem;
          border-radius: 0.2rem;
          padding: 0;
        }
      }
    }
  }
}

.back {
  position: fixed;
  top: 0.2rem;
  left: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.1rem;
  z-index: 999;
}
</style>
